import { Box, Button, Typography, CircularProgress } from "@mui/material";
import Products from "../../Assets/images/aceaid-300.png";

const StartScreen = ({ onStart, isLoading, content }) => {
  return (
    <Box textAlign={"center"}>
      <img style={{ maxWidth: "100%" }} src={Products} alt="Products" />
      {isLoading ? (
        <Box mt={4}>
          <CircularProgress color="primary" />
        </Box>
      ) : (
        <>
          <Typography variant="h1" pt={4} textAlign={"center"} paragraph>
            {content && content.startTitle
              ? content.startTitle
              : "סקר אודות המוצר AceAid"}
          </Typography>
          <Typography variant="body1" textAlign={"left"}>
            {content && content.startContent ? (
              <span style={{ whiteSpace: "pre-wrap" }}>
                {content.startContent}
              </span>
            ) : (
              ""
            )}
          </Typography>
          <Box mt={2}>
            <Box
              display={"flex"}
              alignItems={"flex-start"}
              width={"80%"}
              m={"auto"}
            >
              <Button
                disabled={isLoading}
                sx={{ m: "auto" }}
                variant="contained"
                onClick={onStart}
              >
                שנתחיל ?
              </Button>
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};
export default StartScreen;
