import React from "react";
import { Button, Grid, Box } from "@mui/material";
import { useTheme } from "@emotion/react";

const QuestionOption = (props) => {
  const theme = useTheme();
  let qValues = props.questionOptions.map((qo) => {
    return (
      <Box style={{ width: "100%" }} key={qo.value}>
        <Button
          onClick={() => props.valueClick(qo.value)}
          key={qo.id}
          sx={{
            marginBottom: theme.spacing(2),
            borderRadius: 30,
            backgroundColor: "#fff",
            width: "100%",
            //borderColor: grey[800],
            borderWidth: qo.value === props.selectedValue ? 2 : 1,
            boxSizing: "border-box",
            borderStyle: "solid",
            borderColor:
              qo.value === props.selectedValue
                ? theme.palette.primary.dark
                : theme.palette.primary.light,
            opacity:
              props.selectedValue === "" || qo.value === props.selectedValue
                ? 1
                : 0.5,
          }}
        >
          {qo.label}
        </Button>
      </Box>
    );
  });
  return (
    <React.Fragment>
      <>
        {props.questionTitle}
        <Grid container direction="column" justify="center" alignItems="center">
          {qValues}
        </Grid>
      </>
    </React.Fragment>
  );
};
export default QuestionOption;
