import { Box, Button } from "@mui/material";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";

const ContinueBackButtons = (props) => {
  return (
    <Box
      display={"flex"}
      justifyContent={"space-between"}
      alignItems={"center"}
    >
      <Button
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderRadius: 40,
        }}
        startIcon={<ChevronRightRoundedIcon size="small" />}
        disabled={props.disableBack}
        onClick={props.back}
      >
        חזור
      </Button>

      <Button
        variant="contained"
        size="medium"
        color="secondary"
        onClick={props.continue}
        disabled={props.disableContinue}
        endIcon={<ChevronLeftRoundedIcon size="small" />}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderRadius: 40,
        }}
      >
        {props.continueLabel ? props.continueLabel : "המשך"}
      </Button>
    </Box>
  );
};

export default ContinueBackButtons;
