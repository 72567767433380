import { Typography } from "@mui/material";
import Lottie from "lottie-react";

import LottieJson from "../../Assets/lottie/Animation - 1700743844982.json";

const FinalScreen = () => {
  return (
    <>
      <Lottie animationData={LottieJson} loop={false} />
      <Typography variant="h1" color={"primary"}>
        תודה שהקדשת לנו מזמנך!
      </Typography>
    </>
  );
};

export default FinalScreen;
