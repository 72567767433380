import { Box, Container } from "@mui/material";
import React from "react";

const Body = (props) => {
  return (
    <Box
      sx={{
        background: "#F5F6F8",
        minHeight: "calc(100vh - 96px)",
        position: "relative",
      }}
      className="classname"
    >
      <Container sx={{ p: 2 }}>{props.children}</Container>
    </Box>
  );
};
export default Body;
