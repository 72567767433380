import React, { useState } from "react";
import QuestionnaireProgress from "../Progress/questionnaireProgress";
import YesNoQuestion from "../YesNoQuestion/YesNoQuestion";
import NumbersRange from "../NumbersRange/numbersRange";
import RangeList from "../RangeList/rangeList";
import OpenQuestion from "../OpenQuestionType/openQuestion";
import FinalScreen from "../FinalScreen/finalScreen";
import axios from "axios";
import {
  mobileVendor,
  mobileModel,
  osName,
  browserName,
  deviceType,
} from "react-device-detect";
import { Box } from "@mui/material";

const QManager = ({ questions }) => {
  const [qAnswers, setQAnswers] = useState([]);
  const [currentStep, setCurrentStep] = useState(1);

  const handleSelectValue = (qid, value) => {
    let tempAnswers = [...qAnswers];

    //check if this is a new answer or an update:
    const answerIndex = tempAnswers.findIndex(
      (answer) => answer.answerId === qid
    );

    if (answerIndex > -1) {
      tempAnswers[answerIndex].answerValue = value;
    } else {
      tempAnswers = [
        ...tempAnswers,
        {
          answerId: qid,
          answerValue: value,
        },
      ];
    }
    setQAnswers(tempAnswers);
  };

  const getAnswerForCurrentQuestion = (qid) => {
    const answerIndex = qAnswers.findIndex((ans) => ans.answerId === qid);
    if (answerIndex > -1) return qAnswers[answerIndex].answerValue;

    return null;
  };

  const handlePreviousQuestion = () => {
    setCurrentStep((prev) => prev - 1);
  };

  const handleNextQuestion = () => {
    setCurrentStep((prev) => prev + 1);
    if (currentStep === questions.length) {
      axios.post("/answers", {
        answers: qAnswers,
        deviceDetails: {
          OSName: osName,
          browserName: browserName,
          DeviceType: deviceType,
          Mobile: mobileModel,
          mobileVendor,
        },
      });
    }
  };

  const renderNextQuestion = () => {
    const q = questions.find((qid) => currentStep === qid.qId);
    if (currentStep > 0 && currentStep <= questions.length) {
      switch (q.qType) {
        case "yesNo":
          return (
            <YesNoQuestion
              question={q.qTitle}
              onSelect={(value) => handleSelectValue(q.qId, value)}
              key={q.qId}
              onNextQuestion={handleNextQuestion}
              onPreviousQuestion={handlePreviousQuestion}
              value={getAnswerForCurrentQuestion(q.qId)}
              isFirst={currentStep === 1}
              isLast={questions.length === currentStep}
              flipAnswers={q.flipAnswers ? true : false}
            />
          );
        case "yesNoDetails":
          return (
            <YesNoQuestion
              question={q.qTitle}
              onSelect={(value) => handleSelectValue(q.qId, value)}
              key={q.qId}
              details
              onNextQuestion={handleNextQuestion}
              onPreviousQuestion={handlePreviousQuestion}
              value={getAnswerForCurrentQuestion(q.qId)}
              isFirst={currentStep === 1}
              isLast={questions.length === currentStep}
              flipAnswers={q.flipAnswers ? true : false}
            />
          );
        case "numbersRange":
          return (
            <NumbersRange
              question={q.qTitle}
              label={"גיל"}
              listOptions={q.qListOptions}
              value={getAnswerForCurrentQuestion(q.qId)}
              onSelect={(e) =>
                handleSelectValue(q.qId, { mainValue: e.target.value })
              }
              onNextQuestion={handleNextQuestion}
              onPreviousQuestion={handlePreviousQuestion}
              isFirst={currentStep === 1}
              isLast={questions.length === currentStep}
            />
          );
        case "range":
          return (
            <RangeList
              question={q.qTitle}
              listOptions={q.qListOptions}
              value={getAnswerForCurrentQuestion(q.qId)}
              onSelect={(value) => handleSelectValue(q.qId, value)}
              onNextQuestion={handleNextQuestion}
              onPreviousQuestion={handlePreviousQuestion}
              isFirst={currentStep === 1}
              isLast={currentStep === questions.length}
              withDetails={q.withDetails}
            />
          );
        case "open":
          return (
            <OpenQuestion
              question={q.qTitle}
              onPreviousQuestion={handlePreviousQuestion}
              onNextQuestion={handleNextQuestion}
              value={getAnswerForCurrentQuestion(q.qId)}
              onSelect={(value) =>
                handleSelectValue(q.qId, { mainValue: value })
              }
              isFirst={currentStep === 1}
              isLast={questions.length === currentStep}
              disableNext
            />
          );
        default:
          <OpenQuestion
            question={q.qTitle}
            onPreviousQuestion={handlePreviousQuestion}
            onNextQuestion={handleNextQuestion}
            value={getAnswerForCurrentQuestion(q.qId)}
            onSelect={(value) => handleSelectValue(q.qId, { mainValue: value })}
            isFirst={currentStep === 1}
            isLast={questions.length}
            disableNext
          />;
      }
    }
  };

  return (
    <>
      {currentStep <= questions.length && (
        <QuestionnaireProgress step={currentStep} total={questions.length} />
      )}
      <Box sx={{ minHeight: currentStep > questions.length ? 0 : "550px" }}>
        {renderNextQuestion()}
      </Box>
      {currentStep > questions.length && <FinalScreen />}
    </>
  );
};
export default QManager;
