import { Box, TextField, Typography } from "@mui/material";
import AnswerBox from "../AnswerBox/AnswerBox";
import ContinueBackButtons from "../ContinueBackButtons/ContinueBackButtons";
import { useEffect, useState } from "react";

const YesNoQuestion = ({
  question,
  onSelect,
  value,
  onNextQuestion,
  onPreviousQuestion,
  details,
  isFirst,
  isLast,
  flipAnswers,
}) => {
  const [currentValue, setCurrentValue] = useState({
    mainValue: "",
    details: "",
  });

  useEffect(() => {
    if (value) setCurrentValue(value);
  }, [value]);

  const handeValue = (value) => {
    setCurrentValue(value);
    onSelect(value);
  };

  return (
    <>
      <Typography variant="body1">{question}</Typography>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"flex-start"}
        maxWidth={"200px"}
        m={"auto"}
      >
        <AnswerBox
          active
          answerValue={"yes"}
          iconName={flipAnswers ? "disappointedFace" : "happyFace"}
          answerLabel="כן"
          onClick={() =>
            handeValue({ mainValue: "yes", details: currentValue.details })
          }
          indicateSelection={currentValue && currentValue.mainValue === "yes"}
        />
        <AnswerBox
          active
          answerValue={"no"}
          iconName={flipAnswers ? "happyFace" : "disappointedFace"}
          answerLabel="לא"
          onClick={() =>
            handeValue({ mainValue: "no", details: currentValue.details })
          }
          indicateSelection={currentValue && currentValue.mainValue === "no"}
        />
      </Box>
      {details && (
        <Box mt={2}>
          <TextField
            id="details"
            label="פרט תשובתך"
            fullWidth
            multiline
            rows={4}
            defaultValue={value && value.details ? value.details : ""}
            onChange={(e) =>
              handeValue({
                mainValue: currentValue.mainValue,
                details: e.target.value,
              })
            }
            variant="standard"
          />
        </Box>
      )}

      <Box
        width={"80%"}
        margin={"auto"}
        position={"absolute"}
        left={0}
        bottom={50}
        right={0}
      >
        <ContinueBackButtons
          back={onPreviousQuestion}
          disabeBack={isFirst}
          disableContinue={!value || isLast}
          continue={onNextQuestion}
          continueLabel={"המשך"}
        />
      </Box>
    </>
  );
};

export default YesNoQuestion;
