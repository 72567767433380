import {
  Box,
  Typography,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";
import ContinueBackButtons from "../ContinueBackButtons/ContinueBackButtons";

const NumbersRange = ({
  question,
  label,
  listOptions,
  value,
  onSelect,
  onNextQuestion,
  onPreviousQuestion,
}) => {
  const createOptionsList = () => {
    let list = [];
    for (var i = listOptions.start; i < listOptions.end; i++) {
      list = [...list, i];
    }
    return list;
  };

  const handleSelectValue = (value) => {
    onSelect(value);
  };

  return (
    <>
      <Typography variant="body1">{question}</Typography>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"flex-start"}
        maxWidth={"300px"}
      >
        <FormControl fullWidth sx={{ mt: 2 }}>
          <InputLabel id="demo-simple-select-label">{label}</InputLabel>
          <Select
            labelId="select-age"
            id="simple-select-age"
            value={value && value.mainValue ? value.mainValue : ""}
            label={label}
            onChange={(value) => handleSelectValue(value)}
          >
            {createOptionsList().map((v) => {
              return (
                <MenuItem key={v} value={v}>
                  {v}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Box>
      <Box
        width={"80%"}
        margin={"auto"}
        position={"absolute"}
        left={0}
        bottom={50}
        right={0}
      >
        <ContinueBackButtons
          disableBack
          back={onPreviousQuestion}
          disableContinue={!value}
          continue={onNextQuestion}
          continueLabel={"המשך"}
        />
      </Box>
    </>
  );
};

export default NumbersRange;
