import { Box, TextField, Typography } from "@mui/material";
import ContinueBackButtons from "../ContinueBackButtons/ContinueBackButtons";
import QuestionOption from "../QuestionOption/questionOption";
import { useEffect, useState } from "react";

const RangeList = ({
  question,
  listOptions,
  value,
  onSelect,
  onNextQuestion,
  onPreviousQuestion,
  disableContinue,
  isLast,
  withDetails = false,
}) => {
  const [currentValue, setCurrentValue] = useState({
    mainValue: "",
    details: "",
  });

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  const handleUpdateValue = (value) => {
    setCurrentValue(value);
    onSelect(value);
  };
  return (
    <>
      <Typography variant="body1">{question}</Typography>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        maxWidth={"300px"}
        mt={4}
        ml={"auto"}
        mr={"auto"}
      >
        <QuestionOption
          questionOptions={listOptions}
          valueClick={(value) =>
            handleUpdateValue({
              mainValue: value,
              details:
                currentValue && currentValue.details
                  ? currentValue.details
                  : "",
            })
          }
          selectedValue={
            currentValue && currentValue.mainValue ? currentValue.mainValue : ""
          }
        />
      </Box>
      {withDetails && currentValue && currentValue.mainValue === "other" && (
        <Box mt={2}>
          <TextField
            id="details"
            label="פרט תשובתך"
            fullWidth
            multiline
            rows={4}
            defaultValue={currentValue.details ? currentValue.details : ""}
            onChange={(e) =>
              handleUpdateValue({
                mainValue: currentValue.mainValue,
                details: e.target.value,
              })
            }
            variant="standard"
          />
        </Box>
      )}
      <Box
        width={"80%"}
        margin={"auto"}
        position={"absolute"}
        left={0}
        bottom={50}
        right={0}
      >
        <ContinueBackButtons
          back={onPreviousQuestion}
          disableContinue={!value || isLast || disableContinue}
          continue={onNextQuestion}
          continueLabel={"המשך"}
        />
      </Box>
    </>
  );
};

export default RangeList;
