import { StylesProvider, jssPreset } from "@mui/styles";
import { create } from "jss";
import rtl from "jss-rtl";
import { heIL } from "@mui/material/locale";
import createCache from "@emotion/cache";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Alert, Container } from "@mui/material";
import Header from "./components/Header/header";
import Body from "./components/Body/body";
import StartScreen from "./components/QuestionnaireStartScreen/startScreen";
import { useEffect, useState } from "react";
import QManager from "./components/QuestionnaireManager/QManager";
import { CacheProvider } from "@emotion/react";
import { prefixer } from "stylis";
import rtlPlugin from "stylis-plugin-rtl";

import axios from "axios";

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

const cacheRtl = createCache({
  key: "muirtl",
  stylisPlugins: [prefixer, rtlPlugin],
});

const theme = createTheme(
  {
    typography: {
      fontFamily: "Rubik, sans-serif",
      h1: {
        fontSize: "2rem",
        fontWeight: 400,
      },
      subtitle1: {
        fontWeight: 500,
        fontSize: "1.2rem",
      },
      body1: {
        fontSize: "1.25rem",
        fontWeight: 300,
      },
      caption: {
        fontSize: "0.9rem",
      },
    },
    palette: {
      primary: {
        main: "#00697F",
        light: "#338798",
        dark: "#004958",
      },
      secondary: {
        main: "#f50057",
        light: "#f73378",
        dark: "#ab003c",
      },
      success: {
        main: "#0BD03B",
        light: "#EDF9F0",
        dark: "#06681E",
      },
      text: {
        primary: "#363740",
        secondary: "#636575",
      },
      background: {
        default: "#F5F6F8",
      },
    },
    spacing: (factor) => `${0.5 * factor}rem`,
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            minWidth: 120,
          },
        },
      },
    },
  },

  { direction: "rtl" },
  heIL
);

//axios.defaults.baseURL = "http://localhost/acetech-api/wp-json/api/v2";
axios.defaults.baseURL = "https://acemanan-tech.com/wp-json/api/v2";

axios.defaults.headers["Content-Type"] = "application/json; charset=UTF-8";

// axios.interceptors.request.use(
//   (request) => {
//     console.log("request", request);
//     return request;
//   },
//   (error) => {
//     console.log("request error", error);
//     return Promise.reject(error);
//   }
// );
// axios.interceptors.response.use(
//   (response) => {
//     console.log("response", response);
//     return response;
//   },
//   (error) => {
//     console.log("response error", error.response, error);
//     return Promise.reject(error);
//   }
// );

function App() {
  const [startQuestionnaire, setStartQuestionnaire] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [openScreenText, setOpenScreenText] = useState(null);
  const [questions, setQuestions] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    axios
      .get("/survey-initials")
      .then((data) => {
        setIsLoading(false);
        setOpenScreenText({
          startTitle: data.data.opening_text_title,
          startContent: data.data.opening_text_content,
        });
        setQuestions(data.data.questions);
      })
      .catch((e) => {
        setIsLoading(false);
        setError(e.response);
      });
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <StylesProvider jss={jss}>
        <CacheProvider value={cacheRtl}>
          <Container maxWidth="sm" sx={{ padding: 0 }}>
            <Header />
            {error ? (
              <Alert severity="error">
                אירעה שגיאה בקבלת הנתונים מהשרת. אנא נסו שוב בעוד מספר דקות
              </Alert>
            ) : (
              <Body>
                {!startQuestionnaire ? (
                  <StartScreen
                    content={openScreenText}
                    isLoading={isLoading}
                    onStart={() => setStartQuestionnaire(true)}
                  />
                ) : (
                  <QManager questions={questions} />
                )}
              </Body>
            )}
          </Container>
        </CacheProvider>
      </StylesProvider>
    </ThemeProvider>
  );
}

export default App;
