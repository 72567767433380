import { Box } from "@mui/material";
import React from "react";
import LogoImage from "../../Assets/images/acetech_logo.svg";

const Header = () => {
  return (
    // <AppBar position="static" sx={{ background: "#fff" }}>
    <Box
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
      p={2}
      sx={{ backgroundColor: "#F5F6F8" }}
    >
      <img src={LogoImage} alt="Logo" />
    </Box>
    // </AppBar>
  );
};

export default Header;
