import { Box, TextField, Typography } from "@mui/material";
import ContinueBackButtons from "../ContinueBackButtons/ContinueBackButtons";

const OpenQuestion = ({
  question,
  onSelect,
  value,
  onPreviousQuestion,
  label,
  disableNext,
  onNextQuestion,
  isFirst,
  isLast,
}) => {
  return (
    <>
      <Typography variant="body1">{question}</Typography>

      <Box mt={2} minHeight={"70vh"}>
        <TextField
          id="details"
          label={label ? label : "הצעותיך לשיפור"}
          fullWidth
          multiline
          rows={4}
          //value={value.details ? value.details : ""}
          onChange={(e) => onSelect(e.target.value)}
          variant="standard"
        />
      </Box>

      <Box
        width={"80%"}
        margin={"auto"}
        position={"absolute"}
        left={0}
        bottom={50}
        right={0}
      >
        <ContinueBackButtons
          back={onPreviousQuestion}
          continue={onNextQuestion}
          continueLabel={isLast ? "סיום" : "המשך"}
        />
      </Box>
    </>
  );
};

export default OpenQuestion;
