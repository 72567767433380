import React from "react";
import { Box, Typography } from "@mui/material";
import { useTheme } from "@emotion/react";
// import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
// import CheckRoundedIcon from "@mui/icons-material/CheckRounded";

const AnswerBox = (props) => {
  const theme = useTheme();

  return (
    <Box
      onClick={() => props.onClick(props.answerValue)}
      sx={{
        cursor: "pointer",
        width: 90,
        height: 90,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        // marginLeft: "auto",
        // marginRight: "auto",
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        borderWidth: props.indicateSelection ? 2 : 1,
        borderStyle: "solid",
        borderRadius: 2,
        boxSizing: "border-box",
        borderColor: props.indicateSelection
          ? theme.palette.primary.dark
          : theme.palette.primary.light,
        alignItems: "center",
        background: "#fff",
        opacity: props.active ? 1 : 0.5,
        "& .MuiTypography-root": {
          fontWeight: 400,
        },
        "& img": {
          textAlign: "center",
          marginBottom: theme.spacing(1),
        },
      }}
    >
      {/* <img
        src={require(`../../Assets/images/${props.iconName}.png`)}
        alt={props.iconName}
      /> */}
      {/* {props.iconName === "happyFace" ? (
        <CheckRoundedIcon fontSize="large" color="primary" />
      ) : (
        <ClearRoundedIcon fontSize="large" color="secondary" />
      )} */}
      <Typography varaint="body1" align="center">
        {props.answerLabel}
      </Typography>
    </Box>
  );
};
export default AnswerBox;
