import { Box, Typography } from "@mui/material";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
  },
}));

const QuestionnaireProgress = ({ step, total }) => {
  return (
    <>
      <BorderLinearProgress
        variant="determinate"
        value={Math.round((step / total) * 100)}
      />
      <Box display={"flex"} justifyContent={"center"} mt={1} mb={4}>
        <Typography variant="caption" color={"text.secondary"}>
          שאלה {step} מתוך {total}
        </Typography>
      </Box>
    </>
  );
};
export default QuestionnaireProgress;
